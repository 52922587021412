@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style-type: none;
    color: #161616;
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

.container {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    margin-top: 100px;
}

.section-title {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.section-title span {
    padding: 12px 18px;
    border: 1px solid rgba(22, 22, 22, 0.2);
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    width: min-content;
    text-align: center;
    color: #161616;
}

.section-title h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    color: #161616;
}

.section-title p {
    font-weight: 300;
    font-size: 16px;
    line-height: 112%;
    color: rgba(22, 22, 22, 0.7);
}

@media (min-width: 640px) {
    .section-title span {
        font-size: 16px;
    }

    .section-title h1 {
        font-size: 40px;
    }

    .section-title p {
        font-size: 18px;
    }
}

@media (min-width: 768px) {
    nav svg {
        display: none;
    }
}

@media (min-width: 1280px) {
    .container {
        padding: 0;
    }
}